.st-profile-img
{
  justify-content: center;
  position: relative;
}

.st-dev-img-container{
  border-radius: 50%;
  overflow: hidden;
  width: 200px;
  height:200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  margin-top: 20px;
  display: block;
}

.st-dev-img-container > .st-profile-img
{ 
  width: 90%;
  display: inline;
}

.st-profile-hasan
{
  transform: translate(10px,0);
}

.st-profile-ahmed
{
  transform: translate(-5px,15px);
}

.st-profile-tony
{
  transform: translate(-10px,0);
}

.st-profile-description-box
{
  min-height: 320px;
  @media(max-width:1024px)
  {
    min-height: 0;
  }
}


.st-profile-divider
{
  @media(max-width:1024px)
  {
    border: 1px groove rgba(255,255,255,0.9);
    margin:40px 30px;   
  }
}