.st-minigame-bridge-knights-border
{
  border: rgba(230, 230, 230) solid 3px;
  width:100px;
  height:50px;
  animation: pulsing-border 2.5s ease-in-out infinite;
  @media(max-width:1200px)
  {
    display: none;
  }
}

.st-minigame-bridge-knights-border:hover
{
  animation: none;
  transform: scale(1.1);
}

.st-minigame-redknight
{
    position: absolute;
    top:100px;
    left:75px;
    width: 100px;
    transition: all 0.2s ease-in-out;
    @media(max-width:1200px)
    {
      display: none;
    }
}

.st-minigame-redknight.position1
{
    left:245px;
}

.st-minigame-redknight.position2
{
    left:425px;
}

.st-speech-bubble
{
    opacity: 0.1;
    transition: all 0.5s ease-in-out;
}

.st-speech-bubble.visible
{
    opacity: 1;
}
