.st-navigation-menu
{
    padding:20px;
    font-size: 20px;
    font-weight: 200;
    font-family: 'Lato', sans-serif;
}

#st-navbar-container
{
  max-width: 1200px;
  margin: auto;
}

#st-navbar{
	width: 100%;
	background-color: rgba(0,0,0,1);
  left: auto;
  padding-bottom: 10px;
  z-index: 1;
  position: relative;
  position: fixed;
  z-index: 100;
  height: 80px;
}

.st-adjust-to-nav
{
  padding-top:80px;
}

.st-logo-nav
{
   width:80px;
   height:80px;
}

#st-navbar-menu{
  justify-content: right;
  padding:25px;
}

.st-logo-nav-name
{
  width:150px;
  margin-left: -15px;
}

@media(max-width:1024px){

  .st-logo-nav-name
  {
    width:140px;
    margin-top:10px;
  }

  #st-navbar
  {
    height: 80px;
  }

  #st-navbar-menu{
    z-index:99;
    transition: all 0.4s ease-in-out;
    transform:  translateX(150%);
    background-color: #000;
    height: 200vh;
    justify-content: left;
  }

  #navItem
  {
    padding: 10px;
    font-size: 24px;
  }
  #st-navbar-menu.open{
    transform:  translateX(30%);
  }
 
  #st-navbar-main.open{
    background-color: rgba(0,0,0,0.8);
  }

  #st-navbar-menu {
    display: flex !important;
    flex-flow: column nowrap !important;
  }

  #st-navbar-menu > a {
    margin-bottom: 30px;
  }

  .st-menu-icon
  {
    position: absolute !important; 
    top:30px; 
    right:30px;
    z-index: 99999; 
    width:10px; 
    min-width:0; 
    border:0; 
    padding:12px;
    pointer-events: visible;
  }

  .st-logo-nav
  {
     width:70px;
     height:70px;
  }

  #st-navbar-container
  {
    pointer-events: none;
  }

  #st-navbar-container.open
  {
    pointer-events: visible;
  }

  .menu-icon
  {
    width: 30px !important;
  }

  .menu-icon::after {
    height: 4px !important;
    box-shadow: 0 11px 0 #fefefe, 0 23px 0 #fefefe !important;
}
}