.st-carousel h1
{
    font-family: 'Roboto';
    font-weight: 300;
    text-shadow: 3px 2px 7px rgba(0,0,0,0.8);

    @media only screen and (max-width: 780px) {
      font-size: 42px;
    }
}

@media only screen and (max-width: 780px)
{
  .st-carousel h1
  {
    font-size: 46px;
  }

  .st-carousel h5
  {
      margin: 0; 
      word-wrap: normal;
      font-size: 21px;
      text-shadow: 3px 2px 7px rgba(0,0,0,0.8);
      padding: 0 30px;
  }

  .st-carousel-mobile
  {
    position: absolute;
  }
  .st-carousel > video, .st-carousel > img 
  {
    filter: brightness(80%);
  }

} 

.st-carousel .st-carousel-callToAction
{
-moz-transition:color 0.2s ease-in;
  -o-transition:color 0.2s ease-in;
  -webkit-transition:color 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 25px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  background: transparent;
  border: rgba(204, 204, 204, 0.5) solid 2px;
  border-radius: 5px !important;
  color: rgba(204, 204, 204) ;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 150px;
  height: 75px;
  }


@media only screen and (min-width: 781px) {


  .st-carousel:hover > video, .st-carousel:hover > img 
  {
    filter: blur(4px) brightness(80%);
  }

  .st-carousel h5
  {
      margin: 0; /* Gives that scrolling effect as the typing happens */
      opacity: 0;
      text-shadow: 3px 2px 7px rgba(0,0,0,0.8);
  }

  .st-carousel h5 > small
  {
    color:#fff;
  }

  .st-carousel:hover h5
  {
    transition: all 0.1s ease-in-out; 
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid #fff; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    animation: typing 1s steps(50, end), blink-caret .75s step-end infinite;
    opacity: 1;
  }

  .st-carousel .st-carousel-callToAction
  {
      opacity:0;
      transform : translate(0, -50px);

  }

 
  .st-carousel .st-carousel-callToAction:hover
  {
    border-color: #fff;
    color: #fff;
    font-weight: bold;
    backdrop-filter: blur(10px);
  }

  .st-carousel:hover .st-carousel-callToAction
  {
      opacity:1;
      transform : translate(0,0) !important;
  }
}

.st-carousel-label
{
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  font-weight: bold;
}

.st-carousel-labels
{
  transform: translate(0,-50px);
}


.ignore-pointer
{
  pointer-events: none;
}

.st-carousel-navigation
{
  margin:0 !important;
  padding-bottom: 20px;
}

.st-carousel-navigation > li 
{
  display: inline;
  padding: 10px;
  color:#fff;
}


.st-carousel-navigation > li input[type="radio"]
{
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: #fff;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}



.st-carousel-navigation > li input[type="radio"]:checked{
  background-color: #fff;
}
