#st-footer{
	bottom: 0;
	background-color: rgba(0,0,0,1);
}

#st-social-medias
{
	text-align: left;
	margin-bottom:20px;
	@media(max-width:1020px)
    {
		text-align: center;
	}
}

#st-social
{
	@media(max-width:1020px)
    {
		display: inline-flex;
	}
}


#st-social > li a
{
	@media(min-width:1020px)
    {
		padding-left:0;
	}
}
