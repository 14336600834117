.st-featured-games-list 
{
    margin-left:0;
}

.text-center-in-small
{
    @media(max-width:1024px)
    {
      text-align: center !important;
    }
}

.st-featured-game
{
    display: inline-block;
    text-align: center;
    position: relative;
    margin: 0 10px 0 0;
    display: inline-block;
    overflow: hidden;
    border-radius: 20px;
}

.st-featured-game > span
{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
}

.st-featured-game:hover > span
{
    position: absolute;
    transform: translate(-50%, -100%);
}

.st-featured-game > *
{
   transition: 0.2s all ease-in-out;
}

.st-featured-game:hover > img 
{
    transform: scale(1.05); 
}