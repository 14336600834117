#st-social > li {
	a {
		color: #FFF;
		:hover{
			text-decoration: underline;
		}
	}

	img {
		height: 35px;
		padding-right: 5px;
	}	
}